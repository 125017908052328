/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  FaChevronRight, FaFacebook, FaTwitter, FaInstagram,
} from 'react-icons/fa';
import { Link } from 'gatsby';
import './footer.css';

const Footer = () => (
  <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 footer-info">
            <h4>SHEISBRAVE</h4>
            <p>
              SheIsBrave is a non-profit organization operating in South Africa,
              focusing on young girls and women from all walks of life.

            </p>
            <div className="social-links mt-3">
              <a href="https://www.twitter.com/sheisbrave_za" aria-label="Twitter" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
              <a href="https://www.instagram.com/sheisbrave.za" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
              <a href="https://www.facebook.com/sheisbrave.za" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Organisation</h4>
            <ul>
              <li>
                <FaChevronRight />
                {' '}
                <Link to="/">Home</Link>
              </li>
              <li>
                <FaChevronRight />
                {' '}
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <FaChevronRight />
                {' '}
                <Link to="/whatwedo">What we do</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Get Involved</h4>
            <ul>
              <li>
                <FaChevronRight />
                {' '}
                <a href="https://forms.gle/1n2nT8YWEAmjnSbr9">Volunteer</a>
              </li>
              <li>
                <FaChevronRight />
                {' '}
                <Link to="/testimonials">Testimonials</Link>
              </li>
              <li>
                <FaChevronRight />
                {' '}
                <Link to="/shop">Shop</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Support</h4>
            <ul>
              <li>
                <FaChevronRight />
                {' '}
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <FaChevronRight />
                {' '}
                <Link to="/privacy">Privacy Policy</Link>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">
        &copy; Copyright
        {' '}
        <strong><span>Sheisbrave</span></strong>
        . All Rights Reserved.
        NPO 213–129.
      </div>
      <div className="credits">
        Developed by
        {' '}
        <a href="https://twitter.com/tebogonomnqa">Tebogo Nomnqa</a>
        .
      </div>
    </div>
  </footer>
);

export default Footer;
