import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'gatsby';
import './header.css';

const Header = () => (
  <header id="header" className="fixed-top">
    <Navbar collapseOnSelect expand="lg">
      <div className="container d-flex align-items-center justify-content-between">

        <Navbar.Brand href="/" className="logo">
          <StaticImage src="../../images/logo.png" alt="logo" className="img-fluid" layout="fixed" width={60} />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="nav-menu justify-content-end">
          <Nav defaultActiveKey="#hero" as="ul">
            <Nav.Item as="li">
              <Link
                to="/"
              >
                Home
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                to="/about"
              >
                About
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                to="/whatwedo"
              >
                What we do
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                to="/shop"
              >
                Shop
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                to="/contact"
              >
                Contact
              </Link>
            </Nav.Item>

          </Nav>
          <a
            className="get-started-btn"
            href="https://forms.gle/1n2nT8YWEAmjnSbr9"
          >
            Join Us
          </a>
        </Navbar.Collapse>
      </div>
    </Navbar>

  </header>
);

export default Header;
